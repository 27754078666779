.hero-panel,
.prefooter-panel {
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }
}

.hero-panel {
  background-color: $white;

  .hero.cloudflare {
    background-size: cover;
    background-position-y: 100%;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      background-position-x: 100%;
    }

    @media (max-width: 1200px) {
      background-position-x: 100%;
      background-position-y: 35%;
    }

    @media (min-width: 2000px) {
      background-position-y: 46%;
    }
  }

  .container {
    .hero-content {
      width: 100%;
      margin-top: -150px;

      @media (min-width: 1200px) and (max-width: 1600px) {
        margin-top: -235px;
      }

      @media (max-width: 1200px) {
        text-align: center;
        padding-top: 0;
        position: absolute;
        top: -145px;
      }

      @include media-breakpoint-down(xs) {
        top: unset;
        bottom: 25px;
        padding: 0 15px;
      }
    }

    .hero-image {
      @media (min-width: 1800px) {
        position: absolute;
        right: -120px;
      }

      @media (max-width: 1799px) {
        width: 100%;
      }

      @include media-breakpoint-down(md) {
        padding-top: 50px;
      }
    }
  }
}

.hero-panel {
  @include mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 650px;
  }

  @include wide {
    min-height: 700px;
  }

  @include hd {
    min-height: 804px;
  }

  > .container {
    @include mobile {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    @media (min-width: 992px) and (max-width: 1199.9px) {
      padding: 0 0;
    }

    @media (min-width: 1200px) and (max-width: 1600px) {
      padding: 0 50px;
    }

    @media (max-width: 1200px) {
      padding: 0;
      max-width: 100%;
    }

    @include media-breakpoint-down(xs) {
      padding: 0;
      background-color: #7f7f7f;
      height: 250px;
    }

    @media (max-width: 391px) {
      height: 325px;
    }

    @media (min-width: 392px) and (max-width: 425px) {
      height: 285px;
    }
  }
}

.hero,
.prefooter {
  background-size: cover;
  height: 250px;
  position: absolute;
  height: 100%;
  width: 100%;
}

.hero {
  @media (max-width: 1200px) {
    position: relative;
    height: 450px;
  }

  @include media-breakpoint-down(xs) {
    position: relative;
    height: 250px;
  }
}

.hero-content {
  .line1 {
    line-height: 0.8;
    letter-spacing: -3px;
    color: $white;
    font-size: 60px;
    font-family: $secondary-font;
    font-style: italic;
    font-weight: 600;
    text-shadow: 1px 2px 1px black;

    span {
      color: #f9cca3;
    }

    @media (max-width: 768px) {
      font-size: 60px;
    }

    @include tablet {
      font-size: 80px;
    }

    @include laptop {
      font-size: 80px;
    }

    @include wide {
      font-size: 112px;
    }
  }

  .line2 {
    font-size: 20px;
    margin-bottom: 12px;
    color: $white;
    letter-spacing: 1px;
    font-style: italic;
    text-shadow: 1px 2px 1px black;
  }

  .line3 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;

    @include laptop {
      font-size: 16px;
    }

    @include wide {
      font-size: 17px;
      letter-spacing: 1.7px;
    }
  }
}

.overlay::after {
  content: '';
  background: rgba($black, 0.4);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.prefooter-panel.overlay::after {
  background: rgba($black, 0.25);
}

.strapline {
  position: absolute;
  text-align: center;
  z-index: 0;
  display: flex;
  align-items: center;
  bottom: 0;
  width: 100%;

  @media (max-width: 1200px) {
    position: relative;
    flex-direction: column;
    background-color: rgb(0 0 0 / 70%) !important;
  }

  .badges {
    position: absolute;
    background-image: url('./images/hero-badges.webp');
    max-width: 600px;
    height: 140px;
    background-repeat: no-repeat;
    left: 0;
    right: 0;
    top: -93px;
    margin: 0 auto;

    @media (max-width: 1200px) {
      position: relative;
      width: 100%;
      top: 0;
      margin-top: 30px;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .container {
    padding: 68px 55px 50px 55px;

    @media (max-width: 1200px) {
      padding: 25px 0 30px 0;
    }

    @include media-breakpoint-down(xs) {
      padding: 25px 25px 30px 25px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    @include laptop {
      display: none;
    }
  }

  p {
    color: $white;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: -0.1px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.prefooter-panel {
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  min-height: 596px;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 40px;
    border-bottom: 2px solid #e9e9e9;
  }

  @include laptop {
    display: flex;
    align-items: center;
  }

  .container {
    position: relative;
    z-index: 1;

    @media (max-width: 1199.98px) {
      max-width: 100%;

      .hero-content {
        text-align: center;
        padding-bottom: 90px;
      }
    }

    @media (max-width: 768px) {
      .hero-content {
        padding-bottom: 70px;
      }
    }
  }
}

.prefooter {
  background-size: cover;
  background-position: 90% 40%;
  background-repeat: no-repeat;

  &::after {
    content: '';
    background: linear-gradient(90deg, #000 -30%, transparent 100%);
    width: 54%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  @include media-breakpoint-down(lg) {
    background-position-x: 80%;
    position: relative;
  }

  @media (min-width: 993px) and (max-width: 1399.9px) {
    background-position-x: 80%;
  }

  @media (min-width: 1400px) and (max-width: 1800px) {
    background-position-x: 100%;
  }
}

/* Template Services */
.template-services{
  .hero-panel{
    min-height: 500px;

    .hero {
      @media (min-width: 1200px) {

        &:after {
          content: "";
          background: -webkit-gradient(linear,left top,right top,color-stop(3%,#232323),color-stop(60%,#23232378),to(transparent));
          background: -webkit-linear-gradient(left,#232323 3%,#23232378 60%,transparent 100%);
          background: -o-linear-gradient(left,#232323 3%,#23232378 60%,transparent 100%);
          background: linear-gradient(90deg,#232323 3%,#23232378 60%,transparent 100%);
          width: 80%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &.cloudflare {
        @media (min-width: 1200px) {
          height: 500px;
        }
      }
    }
  }
  

  &.chiropractic-care,
  &.exercise-therapy {
    .hero-panel {
      .hero {
        &.cloudflare {
          background-position-y: 18%;
        }
      }
    }
  }
}
/* Template Services */


//Interior pages with Hero and Strapline
.int-hero-strapline-wrapper {
  @include laptop {
    min-height: 470px;
  }

  @include desktop {
    min-height: 700px;
  }

  #int-hero-container,
  #int-hero-container-desktop {
    @include tablet {
      margin-top: 180px;
    }

    @include laptop {
      margin-top: -130px;
    }

    @include desktop {
      margin-top: -240px;
    }

    @media (max-width: 1200px) {
      margin-top: 0;
    }

    @include media-breakpoint-down(xs) {
      background: #7f7f7f;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}

.interior-hero-strapline {
  background: rgba(0,0,0,0.5);

  .strapline-content {
    color: white;
    margin-bottom: 0;
  }

  .emblems {
    margin-bottom: 20px;
  }

  .container {
    padding: 40px 55px 15px 55px;
  }

  @media (min-width: 1200px) {
    display: block !important;
  }
}

@media (max-width: 1200px) {
  #int-hero-strapline-mobile {
    display: block !important;

    @media (min-width: 575.98px) {
      padding-top: 25px;
    }

    .badges {
      margin-top: 0;
    }

    .container {
      padding: 20px 15px 20px 15px;
    }
  }
}

#int-hero-strapline {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

//About Strapline
.int-strapline-wrapper {
  @include laptop {
    display: block;
    height: 350px;
  }

  @include desktop {
    height: 185px;
  }
}
/* Inner hero with hero and strapline */

/* Inner Hero */
.int-hero-wrapper{
  &.no-strapline{
    @include media-breakpoint-down(xs){
      min-height: 543px;

      #int-hero-container{
        background: #7f7f7f;
        padding: 25px;
      }

      .int-hero-ctas .btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 15px 25px;
      }
    }
  }
}
