.sideNav {
  width: $sideNavWidth;
  #{$sideNavDirection}: -$sideNavWidth;
  box-shadow: inset 7px 0 9px -7px rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 1000;
  height: 100%;
  top: 0;
  background: $brand-primary;
  transition: all 0.3s ease;
  overflow-y: auto;
  padding: 20px 0;

  a {
    display: block;
    color: $white;
    font-size: 16px;
    padding: 10px 20px;
    transition: all 0.7s ease;

    &:hover {
      background-color: $brand-primary;
    }
  }

  ul.nav a {
    display: inline-flex;
    align-items: center;
  }

  li.button {
    margin-bottom: 10px;

    > a {
      font-weight: 500;
    }
  }

  &.sideNav-open {
    #{$sideNavDirection}: 0;

    @media (min-width: 1200.9px) {
      #{$sideNavDirection}: -$sideNavWidth;
    }
  }

  .sub-menu {
    background-color: rgb(0 0 0 / 25%);
    display: none;
    padding: 10px 0;

    li {
      padding: 0 14px;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

/* classes applied to the body */
.sideNavBody {
  overflow-x: hidden;
  position: relative;
  #{$sideNavDirection}: 0;
  transition: all 0.3s ease;
}

.sideNavBody-push {
  #{$sideNavDirection}: $sideNavWidth;

  @media (min-width: 1200.9px) {
    #{$sideNavDirection}: 0;
  }
}
