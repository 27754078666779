footer.footer-panel {
  background-color: $brand-secondary;
  padding-bottom: 30px;
  position: relative;

  .footer-decoration,
  .content-decoration {
    position: absolute;
    z-index: 1;
    top: -28px;
  }

  .brand {
    z-index: 2;
    position: absolute;
    top: -70px;

    @media (max-width: 768px) {
      position: relative;
      top: 0;
    }
  }

  a {
    color: $body-color;
    font-size: 15px;
    font-weight: 500;

    &:hover {
      color: $brand-primary;
    }
  }

  a[href^="tel:"] {
    color: $body-color !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 215px;

    @media (min-width: 768px) and (max-width: 880px) {
      height: 243px;
    }

    @media (max-width: 992px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      height: auto;
    }

    .contact-info {
      margin: 40px 0 20px 0;
      font-size: 15px;
      font-weight: 500;

      span {
        display: block;
        letter-spacing: -0.2px;
        padding-bottom: 15px;

        @include tablet {
          display: inline;
        }
      }

      .fa-brands {
        font-size: 17px;
      }

      span:not(:last-of-type) {
        @include tablet {
          &::after {
            content: '|';
            margin: 0 20px;
          }
        }
      }
    }
  }

  ul.nav {
    @media (max-width: 767.9px) {
      gap: 12px;
    }

    li {
      padding: 0 33px;
      padding-bottom: 10px;
    }
  }
}

footer.copyright {
  background-color: #ebe5d7;
  min-height: 30px;
  display: flex;
  align-items: center;
  font-family: $primary-font;
  font-weight: 500;
  position: relative;

  *,
  a:hover {
    font-size: 12px;
    color: $black;

    @include media-breakpoint-down(md) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  a::after {
    display: none;
  }
}
