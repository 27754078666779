.page-content-panel p a[target="_blank"]::after {
  content: '\f08e';
  font-family: 'Font Awesome 6 Pro', sans-serif;
  font-size: 85%;
  font-weight: 400;
  margin: 0 5px;
  display: inline-block;
}

.no-hover {
  &:hover {
    color: $brand-primary;
  }
}